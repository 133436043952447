.landing-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}

/* Nav Bar Styling */
.nav-bar-display {
  min-width: 340px;
  width: 340px;
  max-width: 340px;
  height: 100vh;
  position: relative;
  background-color: var(--nav-bar-bg-color);
}

/* Open Nav Bar Button */
.navbar-open-button-container {
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  margin-left: 12.5px;
  margin-top: 25px;
  position: absolute;
  z-index: 0;
}

.fa-solid.fa-angles-right {
  border: 2px solid var(--input-border-color);
  color: var(--input-border-color);
  padding: 5px;
  border-radius: 50px;
  background-color: var(--nav-bar-bg-color);
  cursor: pointer;
  position: relative;
}

/* Content for the Landing Page */
.landing-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.landing-inner-left-container {
  min-width: 25px;
  width: 25px;
  max-width: 25px;
  height: 100%;
  position: relative;
  background-color: var(--nav-bar-bg-color);
}

.landing-inner-right-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
