.topic-list-container {
  width: auto;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

/* Topics - navigation bar with header and create topic button */
.topic-list-nav {
  border-bottom: 1px solid rgba(46, 64, 86, 0.26);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topic-list-header {
  margin-left: 36px;
  font-size: 16px;
  font-weight: 400;
}

.create-topic-plus-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 36px;
  color: var(--grey-accent-font-color);
  font-size: 14px;
}

/* Topic List */
.topic-list-item-outer-container{
  height: 125vh;
  overflow-y: scroll;
}

.topic-list-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  margin: 0;
  position: relative;
  max-width: 100%;
}

.topic-list-item-text {
  width: 45vw;
  height: auto;
  padding: 24px 30px 24px 36px;
  cursor: pointer;

  text-decoration: none;
  color: #197096;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin: 0 18px 0 0; */
}

.topic-list-item-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-steps-in-topics {
  cursor: pointer;
  background-color: #7D92A3;
  border-radius: 24px;
  color: #FFF;
  font-size: 15px;
  font-weight: 300;
  height: 42px;
  line-height: 9px;
  padding: 0;
  position: relative;
  border: none;
  width: 100px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}