.splash-container {
  display: flex;
  flex-direction: column;
}

/* Navigation Bar */

.splash-nav {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  margin: 0 auto;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 7;
  background-color: #FFFFFF;
}

.splash-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  width: 110px;
}

#splash-logo-color {
  color: var(--blue-accent-font-color);
}

.splash-buttons {
  border: none;
  height: 100%;
  width: 125px;
  cursor: pointer;
  padding: 10px;
}

.splash-buttons:hover {
  border: none;
  height: 100%;
  width: 125px;
  cursor: pointer;
  opacity: 0.9;
}

/* Container for Log In and Sign Up */
.splash-start-container {
  height: 100%;
}

.splash-buttons#splash-orange {
  color: var(--light-font-color);
  background-color: var(--orange-button-color);
  font-weight: 600;
}

.splash-buttons#splash-teal {
  color: #000;
  background-color: var(--teal-button-color);
  font-weight: 600;
}

/* Main Content */
.splash-main-content {
  /* height: calc(100vh - 80px - 150px); */
  position: static;
  display: flex;
  align-items: flex-end;
}

.splash-images {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.splash-quote-container {
  position: absolute;
  z-index: 1;
  background-color: rgba(219, 219, 219, 0.005);
}

.splash-quote {
  font-size: 70px;
  padding-left: 40px;
  color: #000;
}

/* Developer footer */

.splash-dev-info{
  height: 150px;
  background-color: #324054;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  
}

.splash-dev-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  font-family: var(--main-fonts);
}

.splash-dev-inner-text {
  margin: 0;
  color: var(--light-font-color);
  font-size: 20px;
}

.splash-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  height: 50px;
  text-decoration: none;
  margin-bottom: 10px;
}

.splash-links>a {
  text-decoration: none;
}

.devLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  cursor: pointer;
  color: var(--light-font-color);
  text-decoration: none;
}

/* About the website */
.splash-about {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-font-color);
}

.splash-content {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash-header-container {
  width: 80%;
  border-bottom: 1px solid rgba(46, 64, 86, 0.26);
}

.splash-headers {
  font-size: 24px;
  font-weight: 600;
  color: var(--orange-button-color);
  text-align: center;
}

.splash-text-container {
  padding: 10px 2px;
}

.splash-text {
  font-size: 16px;
  color: var(--grey-accent-font-color);
  text-align: center;
  padding: 0 100px;
}