/* TODO Add site wide styles */
:root {
  --main-fonts: 'Open Sans', sans-serif;
  --main-font-color: #555555;
  --light-font-color: #FFFFFF;
  --blue-accent-font-color: #324A6B;
  --grey-accent-font-color: #878787;
  --orange-button-color: #FF8243;
  --teal-button-color: #2AA5DC;
  --grey-button-color: #4b4040;

  --dark-bg-color: #6b5e67;
  --light-bg-color: #CCCCCC;
  --nav-bar-bg-color: #324054;

  --input-border-color: #C1D6E0;
  --input-border: 2px solid rgba(25, 112, 150, 0.3);
  --input-border-radius: 6px;
  --input-font-color: #737373;
}


html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0px;
}

button {
  font-family: 'Open Sans', sans-serif;
}



.error-container {
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

}

.error-text {
  color: red;
  font-size: 14px;
}

.logout-button {
  cursor: pointer;
  background-color: var(--orange-button-color);
  border-radius: 24px;
  font-size: 12px;
  height: 48px;
  width: fit-content;
  margin: 0;
  border: none;
  color: #FFFFFF;
  font-weight: 400;
  justify-content: center;
  padding: 0 25.2px;
}

.demo-user-button {
  background-color: var(--orange-button-color);
  color: var(--main-font-color);
  border-radius: var(--input-border-radius);
  border: none;
  padding: 7px;
  cursor: pointer;
  height: 2.5em;
  border-radius: 5px;
  width: 98%;
  margin-left: 4px;
}

.redirect-container {
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redirect-container>h1{
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.redirect-icon {
  color:rgba(25, 112, 150, 0.3);
}