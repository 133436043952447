.category-list-outer-container{
  padding-top: 20px;
}

.category-list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 20px;
}

.category-list-container:hover {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(128, 128, 128, 0.559);
}

.category-list-container#active {
  cursor: pointer;
  padding-left: 10px;
  border-left: 10px solid var(--light-font-color);
  box-sizing: border-box;
}

.category-list-icon {
  height: 40px;
  width: 40px;
  object-fit: scale-down;
  background-color: var(--light-bg-color);
  padding: 4px;
  box-sizing: border-box;
}

.category-list-text {
  text-decoration: none;
  font-size: 16px;
  color: var(--light-font-color);
  padding-left: 10px;
  width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.category-list-navtext {
  width: 100%;
  text-decoration: none;
  font-size: 16px;
  color: var(--light-font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category-list-text:hover {
  text-decoration: none;
  font-size: 16px;
  color: var(--light-font-color);
  padding-left: 10px;
}