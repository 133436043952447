.category-detail-overview-container {
  display: flex;
  flex-direction: row;
  padding: 36px;
}

.category-detail-overview-icon-container {
  width: 130px;
  height: 130px;
  padding: 5px;
  box-sizing: border-box;
  background-color: var(--teal-button-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-detail-icon {
  width: 85%;
  height: 85%;
}

.category-detail-overview-info {
  display: flex;
  flex-direction: column;
  margin-left: 36px;
}

#category-name-text {
  color: var(--blue-accent-font-color);
  line-height: 1;
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 50px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50vw;
}

.category-detail-overview-buttons {
  display: flex;
  flex-direction: row;
}

.delete-category-button {
  cursor: pointer;
  background-color: var(--orange-button-color);
  border-radius: 24px;
  color: #FFF;
  font-size: 15px;
  font-weight: 300;
  height: 42px;
  line-height: 9px;
  padding: 0;
  position: relative;
  border: none;
  width: 100px;
  margin-left: 10px;
}

/* Nav bar for Category Detail */
.category-detail-nav {
  height: 45px;
  background-color: #ECF2F6;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.category-nav-tabs-container {
  height: 45px;
  width: 50%;
}

.category-nav-tabs {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: var(--main-fonts);
  font-size: 14px;
  cursor: pointer;
  font-weight: 400px;
  color: var(--blue-accent-font-color)
}

.category-nav-tabs-container>a {
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 500;
}

.category-nav-tabs-container>a.active {
  background: unset;
  color: var(--text-color);
  border-bottom: 8px solid #9FAFC8;
}

.category-nav-tabs-container>a:hover:not(.active) {
  font-weight: 200;
  background: unset;
  color: var(--text-color);
  border-bottom: 8px solid #9FAFC5;
}

.category-detail-inner-content {
  height: 100%;
}

/* About Content */
.category-detail-about-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: scroll;
}

.category-detail-about-inner-left {
  background-color: #ECF2F6;
  height: 100vh;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  position: fixed;
  box-sizing: border-box;
}

.category-detail-about-inner-right {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  padding: 24px 42px;
  box-sizing: border-box;
  margin-left: 200px;
  width: 100%;
  height: 400vh;
  /* overflow-y:scroll;  */
}

/* About - Inner Left Nav Bar */
.category-detail-about-inner-left-navbar {
  padding: 6px 24px 24px 0;
  display: flex;
  flex-direction: column;
}

.about-inner-left-navbar-link-container {
  box-sizing: border-box;
  padding: 10px 0 12px 24px;
}

.about-inner-left-navbar-link-container#active {
  border-left: 8px solid rgba(18, 57, 108, 0.36);
  box-sizing: border-box;
}

.about-inner-left-navbar-link-container#active>a {
  font-weight: 600;
}

.about-inner-left-navbar-text {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: var(--text-color)
}

/* About - content */
.category-detail-header-containers {
  border-bottom: 1px solid rgba(46, 64, 86, 0.26);
  height: 30px;
  padding: 6px;
  box-sizing: border-box;
  position: relative;
}

#category-detail-headline,
#category-detail-description,
#category-detail-purpose {
  color: #29A5DC;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.category-detail-inner-text {
  padding: 4px;
  font-size: 16px;
  color: var(--grey-accent-font-color);
  position: relative;
  margin-bottom: 40px;
  word-break: break-all;
}