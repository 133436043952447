.navbar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-upper {
  max-width: 100%;
  width: 100%;
}

.navbar-upper-test {
  width: 100%;
}

.navbar-lower {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.navbar-button-container {
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  margin-top: 25px;
  margin-left: 340px;
  position: absolute;
  z-index: 0;
}

.fa-solid.fa-angles-left {
  border: 2px solid var(--input-border-color);
  color: var(--input-border-color);
  padding: 5px;
  border-radius: 50px;
  background-color: var(--nav-bar-bg-color);
  cursor: pointer;
  position: relative;
}

/* Top section of Nav Bar */
.navbar-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.fa-solid.fa-meteor {
  color: var(--light-bg-color);
}

.user-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  width: 34%;
}

.user-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border: var(--input-border);
  border-radius: 50%;
}

.navbar-profile-text-container {
  width: 100%;
}

.navbar-profile-text {
  color: var(--light-font-color);
  font-size: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  text-align: center;
}

.navbar-logout-container {
  display: flex;
  align-items: center;
}

/* My Categories */
.navbar-category-header-container {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
}

.navbar-category-header-inner-container {
  display: flex;
  flex-direction: row;
  width: 95%;
  border-bottom: 1px solid rgba(127, 133, 146, 0.44);
}

.navbar-category-text {
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--light-font-color)
}

#navbar-count {
  padding-left: 8px;
}

/* Category List Container */