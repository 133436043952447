.login-form-container {
  width: 30vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Cancel button */
.cancel-button-container {
  display: flex;
  justify-content: flex-end;
}

i.fa-solid.fa-xmark.fa-lg {
  color: var(--dark-font-color);
}

/* Form Header */
.login-form-header-container {
  display: flex;
  justify-content: center;
}

.login-form-header {
  margin: 0;
  color: var(--blue-accent-font-color);
}

.login-form-inner-container {
  margin-top: 15px;
  padding: 5px;
}

.login-form-sections {
  display: flex;
  flex-direction: column;
}

.login-form-required-text {
  font-size: 12px;
  color: red;
  margin: 0;
  padding-left: 5px;
  padding-bottom: 2px;
}

/* - Styling for form elements -  */
.login-form-labels {
  font-size: 16px;
  color: var(--main-font-color);
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.login-form-inputs::placeholder {
  color: var(--input-font-color);
}


.login-form-inputs {
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  color: var(--blue-accent-font-color);
  font-size: 18px;
  font-weight: 700;
  outline: none;
  padding: 12px 18px;
  margin-bottom: 10px;
}



/* Button Styling */
.login-form-button-container {
  width: 100%;
}

.login-submit-button {
  background-color: var(--orange-button-color);
  color: var(--main-font-color);
  border-radius: var(--input-border-radius);
  border: none;
  padding: 7px;
  cursor: pointer;
  height: 2.5em;
  border-radius: 5px;
  width: 100%;
}

.login-submit-button-disabled {
  color: var(--light-font-color);
  background-color: #CECECE;
  border: none;
  height: 2.5em;
  border-radius: 5px;
  cursor: not-allowed;
  width: 100%;
}