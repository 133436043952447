.sc-outer-container {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.sc-container {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  color: var(--orange-button-color);
}

.sc-wrapper {
  display: flex;
  justify-content: center;
  max-width: 95%;
  width: 95%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.sc-content-container {
  overflow: hidden;
  max-width: 80%;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: row;
}

.sc-content-card {
  display: flex;
  transition: all 250ms linear;
  flex-direction: column;
  overflow: hidden;
  width: 800px;
  background-color: aqua;
}

.sc-content {
  border: 2px solid #324a6b;
  width: 100%;
  border-radius: 8px;
  max-width: 100%;
}

.sc-content-color-bar {
  width: 100%;
  background-color: var(--orange-button-color);
  height: 30px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.sc-content-inner-container {
  padding: 20px 50px;
}

.sc-header-container {
  border-bottom: 1px solid rgba(46, 64, 86, 0.26);
}

.sc-headers {
  font-size: 24px;
  font-weight: 500;
  color: var(--teal-button-color);
}

.sc-inner-text-container {
  width: 100%;
  max-height: 30%;
  height: auto;
  max-width: 100%;
  word-break: break-all;
}

.sc-inner-text {
  font-size: 18px;
  color: var(--main-font-color);
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 100%;
  width: 100%;
  word-break: break-all;
}

.sc-inner-text-container#sc-description {
  height: auto;
  max-width: 100%;
  width: 100%;
  word-break: break-all;
}

.sc-left-arrow,
.sc-right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.sc-left-arrow {
  left: 40px;
}

.sc-right-arrow {
  right: 40px;
}

.no-steps {
  display: flex;
  flex-direction: column;
  padding: 60px;
  justify-content: center;
  align-items: center;
  color: var(--main-font-color);
}

.no-steps>h1{
  margin: 0;
  font-weight: 400;
  margin-bottom: 10px;
}

.no-step-icon {
  margin: 30px;
}