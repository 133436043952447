#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  overflow: scroll;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #162834;
  opacity: .98;
}

#modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #FFFFFF;
  width: auto;
  height: auto;
  padding: 50px;
  border-radius: 10px;
  top: 100px;
}