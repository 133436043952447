.create-step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.create-step-form {
  display: flex;
  flex-direction: row;
  width: calc(100vw - 500px);
  border: 1px solid #C0CAD1;
  box-shadow: -1px 1px 6px #d5e1e9;
  border-radius: 6px;
  padding: 5px;
  padding-top: 15px;
}

.create-step-new-card-text {
  font-size: 16px;
  color: #197096;
  font-weight: 600;
  margin: 0;
  width: 60px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 15px;
}

.create-step-input-container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.step-req-remain-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.step-form-required-text {
  font-size: 8px;
  color: red;
  font-weight: 400;
  text-align: start;
  margin: 0;
  margin-left: 2px;
  width: fit-content;
}

.create-step-char-remaining {
  text-align: end;
  font-size: 10px;
  margin: 0;
  margin-right: 2px;
  color: var(--grey-accent-font-color)
}

.create-step-inputs::placeholder {
  color: var(--input-font-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.create-step-inputs {
  height: 84px;
  border: 2px solid #cccccc;
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 14px;
  resize: none;
  font-family: var(--main-fonts);
  padding-top: 10px;
  color: var(--blue-accent-font-color);
  font-weight: 700;
  line-height: 16px;
}

#right-border {
  border-right: 5px solid #cccccc;
}

.create-step-inputs-left::placeholder {
  color: var(--input-font-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 78px;
}

.create-step-inputs-left {
  height: 78px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 2px solid #cccccc;
  padding-left: 10px;
  font-family: var(--main-fonts);
  color: var(--blue-accent-font-color);
  font-weight: 700;
  line-height: 78px;
}

.create-step-inputs-right::placeholder {
  color: var(--input-font-color);
  font-size: 14px;
  font-weight: 600;
}

.create-step-inputs-right {
  height: 84px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 2px solid #cccccc;
  box-sizing: border-box;
  padding-left: 15px;
  font-size: 14px;
  resize: none;
  font-family: var(--main-fonts);
  padding-top: 10px;
  color: var(--blue-accent-font-color);
  font-weight: 700;
}

.step-edit-buttons {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.create-step-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--teal-button-color);
  margin-bottom: 15px;
}

.delete-set-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--orange-button-color);
}

.create-step-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-step-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 20px;
}

.step-form-header {
  color: var(--blue-accent-font-color);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
}

.available-steps {
  font-size: 20px;
  color: var(--orange-button-color);
}

.success-text {
  color: green;
  font-size: 14px;
}